


























































































import { IOption } from "@/models/interface-v2/common.interface";
import { ResponseListOfTaxInvoiceCode } from "@/models/interface-v2/tax-invoice.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataListRelatedCode } from "@/models/interface/settings.interface";
import { settingsServices } from "@/services/settings.service";
import { formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
import Vue from "vue";
export default Vue.extend({
  name: "CustomerDetails",
  props: ["dataCustomerType", "customerNumber"],
  data() {
    return {
      formRules: {
        customerType: {
          label: this.$t("lbl_customer_type"),
          name: "customerType",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "customerType",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        creditLimit: {
          label: this.$t("lbl_credit_limit"),
          name: "creditLimit",
          placeholder: this.$t("lbl_type_here"),
          decorator: ["creditLimit"],
        },
        discount: {
          label: this.$t("lbl_discount"),
          name: "discount",
          placeholder: this.$t("lbl_type_here"),
          decorator: ["discount"],
        },
        taxInvoiceCode: {
          label: this.$t("lbl_tax_invoice_code"),
          name: "taxInvoiceCode",
          placeholder: this.$t("lbl_choose"),
          decorator: ["taxInvoiceCode"],
        },
        customerNumber: {
          label: this.$t("lbl_customer_number"),
        },
      },
      dtOpt: {
        tax: [] as IOption<DataListRelatedCode>[]
      },
      loading: {
        tax: false,
      },
      queryParams: {
        page: 0,
        limit: 10,
        search: "",
        sorts: ""
      },
      dtTax: {} as ResponseListOfTaxInvoiceCode,
    };
  },
  created() {
    this.getListTax(this.queryParams);
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    async getListTax(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading.tax = true;
        const res = await settingsServices.listOfTaxInvoiceCode(params);
        this.dtOpt.tax = res.data.map(x => ({ key: x.code, value: x.id, meta: x }));
        this.dtTax = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.tax = false;
      }
    },
    searchTax(search = ""): void {
      const searchBy: string[] = [];
      let findBy = "";
      if (search) {
        searchBy.push(`code~*${search}*`);
        findBy = searchBy.join("_AND_");
      } else {
        findBy = searchBy.join();
      }
      this.queryParams.page = 0;
      this.queryParams.search = findBy;
      this.dtOpt.tax = [];
      this.getListTax(this.queryParams);
    },
    popupScrollTax(e): void {
      if ((this.dtTax.totalPages - 1) === this.dtTax.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getListTax(this.queryParams);
      }
    },
  }
});
