var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticClass: "p-3", attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        { attrs: { span: 18, xs: 24, s: 24, md: 24, lg: 18 } },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.customerType.label } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: _vm.formRules.customerType.decorator,
                      expression: "formRules.customerType.decorator"
                    }
                  ],
                  attrs: {
                    name: _vm.formRules.customerType.name,
                    placeholder: _vm.formRules.customerType.placeholder,
                    "allow-clear": true
                  }
                },
                _vm._l(_vm.dataCustomerType, function(data, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: data.value } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s(data.value) + " ")
                          ]),
                          _vm._v(" " + _vm._s(data.value) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.creditLimit.label } },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.creditLimit.decorator,
                    expression: "formRules.creditLimit.decorator"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  name: _vm.formRules.creditLimit.name,
                  placeholder: _vm.formRules.creditLimit.placeholder,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  min: 0
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.discount.label } },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.discount.decorator,
                    expression: "formRules.discount.decorator"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  name: _vm.formRules.discount.name,
                  placeholder: _vm.formRules.discount.placeholder
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t("lbl_tax_invoice_code") } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: _vm.formRules.taxInvoiceCode.decorator,
                      expression: "formRules.taxInvoiceCode.decorator"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: _vm.$t("lbl_choose"),
                    "allow-clear": "",
                    loading: _vm.loading.tax,
                    "dropdown-match-select-width": false,
                    "show-search": ""
                  },
                  on: { search: _vm.searchTax, popupScroll: _vm.popupScrollTax }
                },
                [
                  _c("a-icon", {
                    attrs: {
                      slot: "clearIcon",
                      type: "close-circle",
                      theme: "filled"
                    },
                    slot: "clearIcon"
                  }),
                  _vm._l(_vm.dtOpt.tax, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.key, attrs: { value: item.value } },
                      [
                        _c(
                          "a-tooltip",
                          [
                            _c("template", { slot: "title" }, [
                              _vm._v(" " + _vm._s(item.key || "-") + " ")
                            ]),
                            _vm._v(" " + _vm._s(item.key || "-") + " ")
                          ],
                          2
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.customerNumber.label } },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.customerNumber ? _vm.customerNumber : null))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }